import { ImageWithSize } from '../types';
import { truthy } from './utils';

const windowDPR = typeof window === 'undefined' ? 1 : window.devicePixelRatio;

export interface CloudinaryOptions {
    maxHeight?: number;
    maxWidth?: number;
    fixedHeight?: number;
    fixedWidth?: number;
    quality?: number;
    crop?: 'crop' | 'fill' | 'fit';
    gravity?: 'north';
    devicePixelRatio?: number;
}

function transformCloudinaryImage(
    image: ImageWithSize,
    options: CloudinaryOptions = {},
): ImageWithSize {
    const {
        maxHeight,
        maxWidth,
        fixedHeight,
        fixedWidth,
        quality,
        crop,
        gravity,
        devicePixelRatio = windowDPR,
    } = options;

    let { height, width } = image;
    const aspectRatio = width / height;

    if (typeof fixedHeight === 'number' && typeof fixedWidth === 'number') {
        height = fixedHeight;
        width = fixedWidth;
    } else if (typeof fixedHeight === 'number') {
        height = fixedHeight;
        width = width = aspectRatio * height;
    } else if (typeof fixedWidth === 'number') {
        width = fixedWidth;
        height = width / aspectRatio;
    }

    if (typeof maxHeight === 'number') {
        if (height > maxHeight) {
            height = maxHeight;
            width = aspectRatio * height;
        }
    }
    if (typeof maxWidth === 'number') {
        if (width > maxWidth) {
            width = maxWidth;
            height = width / aspectRatio;
        }
    }

    const actualWidth = width * devicePixelRatio;
    const actualHeight = height * devicePixelRatio;

    const settings = [
        `w_${Math.floor(actualWidth)}`,
        `h_${Math.floor(actualHeight)}`,
        crop ? `c_${crop}` : undefined,
        quality ? `q_${quality}` : 'q_auto',
        gravity ? `g_${gravity}` : undefined,
        'f_auto',
    ].filter(truthy);

    const url = image.url
        .replace('/image/upload', `/image/upload/${settings.join(',')}`)
        .replace(/^http:\/\//i, 'https://'); // Replace http to https

    return { url, height, width };
}

export { transformCloudinaryImage };
