import { useEffect, useState } from 'react';
import { Size } from '../types';
import { debounce } from './debounce';

function getWindowSize() {
    return {
        width: window.innerWidth,
        height: window.innerHeight,
    };
}

function useWindowSize(wait: number = 0) {
    // Initialize state with undefined width/height so server and client renders match
    // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
    const [windowSize, setWindowSize] = useState<Size>();

    useEffect(() => {
        // Handler to call on window resize
        function handleResize() {
            // Set window width/height to state
            setWindowSize(getWindowSize());
        }

        const resizeHandler =
            wait > 0 ? debounce(handleResize, wait) : handleResize;

        // Add event listener
        window.addEventListener('resize', resizeHandler);

        // Call handler right away so state gets updated with initial window size
        handleResize();

        // Remove event listener on cleanup
        return () => window.removeEventListener('resize', resizeHandler);
    }, []); // Empty array ensures that effect is only run on mount

    return windowSize;
}

export { useWindowSize };
