import { useWindowSize } from './useWindowSize';

const mobileBreakpoint = 640;

const belowEqualMobile = `@media (max-width: ${mobileBreakpoint}px)`;
const aboveMobile = `@media (min-width: ${mobileBreakpoint + 1}px)`;

function isMobileWidth(width: number) {
    return width <= mobileBreakpoint;
}

function isDesktopWidth(width: number) {
    return width > mobileBreakpoint;
}

function useIsMobile() {
    const windowSize = useWindowSize();

    return windowSize ? isMobileWidth(windowSize.width) : false;
}

export {
    belowEqualMobile,
    aboveMobile,
    isMobileWidth,
    isDesktopWidth,
    useIsMobile,
};
