import React from 'react';
import styled from '@emotion/styled';

interface IsOpenProps {
    isOpen?: boolean;
}

const height = 30;

const Button = styled.button`
    width: 30px;
    height: ${height}px;
    position: relative;
    transform: rotate(0deg);
    transition: 500ms ease-in-out;
    cursor: pointer;
    border: none;
    background-color: transparent;

    &:focus {
        outline: none;
    }
`;

const Line = styled.span`
    display: block;
    position: absolute;
    height: ${height / 5}px;
    width: 100%;
    background-color: #3e3e3e;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: 250ms ease-in-out;

    &:nth-of-type(1) {
        top: ${(props: IsOpenProps) =>
            props.isOpen ? `${0.4 * height}` : '0'}px;
        transform: rotate(
            ${(props: IsOpenProps) => (props.isOpen ? '135' : '0')}deg
        );
    }

    &:nth-of-type(2) {
        top: ${0.4 * height}px;
        opacity: ${(props: IsOpenProps) => (props.isOpen ? '0' : '1')};
        transform: translateX(
            ${(props: IsOpenProps) => (props.isOpen ? '-100%' : '0')}
        );
    }

    &:nth-of-type(3) {
        top: ${(props: IsOpenProps) =>
            props.isOpen ? `${0.4 * height}` : `${0.8 * height}`}px;
        transform: rotate(
            ${(props: IsOpenProps) => (props.isOpen ? '-135' : '0')}deg
        );
    }
`;

type HamburgerButtonProps = {
    isOpen: boolean;
    onClick?: () => void;
    className?: string;
};

function HamburgerButton(props: HamburgerButtonProps) {
    const { isOpen, onClick, className } = props;

    return (
        <Button type="button" onClick={onClick} className={className}>
            <Line isOpen={isOpen} />
            <Line isOpen={isOpen} />
            <Line isOpen={isOpen} />
        </Button>
    );
}

export { HamburgerButton };
