import React from 'react';
import styled from '@emotion/styled';
import { DesktopNavbar } from './DesktopNavbar';
import { MobileNavbar } from './MobileNavbar';
import { Container } from './Container';
import logo from '../../assets/logo.png';
import { NavbarMenuItem } from '../../types';
import { aboveMobile, belowEqualMobile } from '../../services/mediaquery';

const Root = styled.header`
    background-color: #ffffff;
    border-bottom: 1px solid #cccccc;
    position: sticky;
    top: 0;
    z-index: 100;
`;

const HeaderContainer = styled(Container)`
    text-align: center;
    ${aboveMobile} {
        display: flex;
        justify-content: space-between;
        padding: 10px 0;
    }
`;

const LogoImage = styled.img`
    height: 117px;
`;

const HeaderDesktopNavbar = styled(DesktopNavbar)`
    margin-top: auto;
    ${belowEqualMobile} {
        display: none;
    }
`;

const HeaderMobileNavbar = styled(MobileNavbar)`
    margin-top: auto;
    ${aboveMobile} {
        display: none;
    }
`;

// const MobileNavbarButton = styled(HamburgerButton)`
//     margin-top: auto;
//     ${aboveMobile} {
//         display: none;
//     }
// `;

interface HeaderProps {
    gallery: NavbarMenuItem[];
    packages: NavbarMenuItem[];
}

function Header(props: HeaderProps) {
    const { gallery, packages } = props;
    return (
        <Root>
            <HeaderContainer>
                <HeaderDesktopNavbar gallery={gallery} packages={packages} />

                <LogoImage src={logo} alt="logo" />

                <HeaderMobileNavbar gallery={gallery} packages={packages} />
            </HeaderContainer>
        </Root>
    );
}

export { Header };
