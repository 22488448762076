import React from 'react';
import { Helmet } from 'react-helmet';
import { transformCloudinaryImage } from '../../services/cloudinary';
import { ImageWithSize, OpenGraph } from '../../types';

interface MetatagsProps extends OpenGraph<ImageWithSize> {}

function Metatags(props: MetatagsProps) {
    const { url, title, description, image } = props;

    const { url: imageUrl } = transformCloudinaryImage(image.original, {
        fixedWidth: 1200,
        devicePixelRatio: 1,
    });

    return (
        <Helmet>
            <meta charSet="utf-8" />
            <meta http-equiv="x-ua-compatible" content="ie=edge" />
            <meta
                name="viewport"
                content="width=device-width, initial-scale=1, shrink-to-fit=no"
            />

            {/* Primary Meta Tags */}
            <title>{title}</title>
            <meta name="title" content={title} />
            <meta name="description" content={description} />
            <link rel="canonical" href={url} />

            {/* Open Graph / Facebook */}
            <meta property="og:type" content="website" />
            <meta property="og:url" content={url} />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            <meta property="og:image" content={imageUrl} />

            {/* Twitter */}
            <meta property="twitter:card" content="summary_large_image" />
            <meta property="twitter:url" content={url} />
            <meta property="twitter:title" content={title} />
            <meta property="twitter:description" content={description} />
            <meta property="twitter:image" content={imageUrl} />
        </Helmet>
    );
}

export default Metatags;
