import styled from '@emotion/styled';
import { belowEqualMobile } from '../../services/mediaquery';

const Container = styled.div`
    width: 1040px;
    margin: 0 auto;

    @media (max-width: 1080px) {
        width: 740px;
    }

    ${belowEqualMobile} {
        width: 100%;
        padding: 16px;
    }
`;

export { Container };
