import React from 'react';
import styled from '@emotion/styled';
import { Container } from './Container';
import instagramIcon from '../../assets/instagram.svg';
import facebookIcon from '../../assets/facebook.svg';
import whatsappIcon from '../../assets/whatsapp.svg';
import { aboveMobile, belowEqualMobile } from '../../services/mediaquery';
import { PersonalInfo } from '../../types';

const FooterContainer = styled(Container)`
    position: relative;
    padding: 10px 0;
    line-height: normal;
    ${belowEqualMobile} {
        margin-top: 30px;
        margin-bottom: 30px;
        text-align: center;
    }
    ${aboveMobile} {
        display: flex;
        align-items: center;
        margin-top: 50px;
        margin-bottom: 80px;
    }
`;

const ContactInfo = styled.div`
    ${belowEqualMobile} {
        font-size: 1.3rem;
        margin-top: 10px;
    }
    ${aboveMobile} {
        order: -1;
        font-size: 1.125rem;
    }
`;

const Copyrights = styled.div`
    direction: ltr;
    margin-top: 5px;
    ${belowEqualMobile} {
        font-size: 0.9rem;
    }
    ${aboveMobile} {
        font-size: 0.8125rem;
        margin-right: auto;
    }
`;

const SocialNetworkLinks = styled.div`
    display: flex;
    justify-content: center;
    ${aboveMobile} {
        margin-right: 10px;
    }
`;

const SocialNetworkLink = styled.a`
    margin: 0 10px;
`;

const Icon = styled.img`
    height: 34px;
    width: 34px;
    display: block;
    ${aboveMobile} {
        height: 24px;
        width: 24px;
    }
`;

type FooterProps = {
    personalInfo: PersonalInfo;
    copyrightText: string;
};

function Footer(props: FooterProps) {
    const { personalInfo, copyrightText } = props;

    return (
        <FooterContainer>
            <SocialNetworkLinks>
                <SocialNetworkLink
                    href={personalInfo.instagramUrl}
                    target="_blank"
                >
                    <Icon src={instagramIcon} alt="instagram" />
                </SocialNetworkLink>
                <SocialNetworkLink
                    href={personalInfo.facebookUrl}
                    target="_blank"
                >
                    <Icon src={facebookIcon} alt="facebook" />
                </SocialNetworkLink>
                <SocialNetworkLink
                    href={personalInfo.whatsappUrl}
                    target="_blank"
                >
                    <Icon src={whatsappIcon} alt="whatsapp" />
                </SocialNetworkLink>
            </SocialNetworkLinks>
            <ContactInfo>{`${personalInfo.name} - ${personalInfo.phoneNumber}`}</ContactInfo>
            <Copyrights>{copyrightText}</Copyrights>
        </FooterContainer>
    );
}

export { Footer };
