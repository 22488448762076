import React, { MouseEvent, useState } from 'react';
import styled from '@emotion/styled';
import { Link } from 'gatsby';
import { ImageWithSize, NavbarMenuItem } from '../../types';
import { HamburgerButton } from '../HamburgerButton';
import ArrowIcon from '../../assets/down-arrow.svg';

interface IsOpenProps {
    isOpen?: boolean;
}

const Root = styled.div`
    text-align: right;
    position: relative;
`;

const Nav = styled.nav`
    padding: 40px;
    font-size: 1.4rem;
    position: fixed;
    top: 185px;
    bottom: 0;
    right: 0;
    left: 0;
    overflow: auto;
    background-color: #ffffffe6;
    transform: translateX(
        ${(props: IsOpenProps) => (props.isOpen ? '0' : '100%')}
    );
    transition: 250ms ease-in-out;
`;

const Anchor = styled(Link)`
    display: flex;
    align-items: center;
    padding: 10px 8px;
    margin: 0 2px;
    text-decoration: none;
    transition: all 250ms ease;
    border-radius: 4px;
`;

const AnchorIcon = styled.img`
    height: 15px;
    width: 15px;
    margin-right: auto;
    transition: transform 150ms ease;
`;

const List: any = styled.ul(
    () => `
    & ${List} ${ListItem}{
        padding-right: 40px;
    }
`,
);

const ListItem = styled.li`
    position: relative;
    & ${List} {
        display: ${(props: IsOpenProps) => (props.isOpen ? 'block' : 'none')};
    }

    & ${AnchorIcon} {
        transform: rotate(
            ${(props: IsOpenProps) => (props.isOpen ? '180' : '0')}deg
        );
    }
    &:hover {
        & ${List} {
            opacity: 1;
            visibility: visible;
        }
    }
`;

const activeListItemStyle = {
    // backgroundColor: '#cccccc',
    fontWeight: 700,
};

interface MobileNavbarProps {
    gallery: NavbarMenuItem[];
    packages: NavbarMenuItem[];
    className?: string;
}

type MenuId = 'gallery' | 'packages';

function MobileNavbar(props: MobileNavbarProps) {
    const { gallery, packages, className } = props;

    const [isOpen, setOpen] = useState(false);

    const [openMenuIds, setOpenMenuIds] = useState<Record<MenuId, boolean>>({
        gallery: false,
        packages: false,
    });

    function closeMenu() {
        setOpen(false);
    }

    function menuToggleCreator(menuId: MenuId) {
        return function toggleMenu(event: MouseEvent) {
            event.preventDefault();
            setOpenMenuIds({
                ...openMenuIds,
                [menuId]: !openMenuIds[menuId],
            });
        };
    }

    return (
        <Root className={className}>
            <HamburgerButton isOpen={isOpen} onClick={() => setOpen(!isOpen)} />
            <Nav isOpen={isOpen}>
                <List>
                    <ListItem>
                        <Anchor
                            to="/"
                            activeStyle={activeListItemStyle}
                            onClick={closeMenu}
                        >
                            בית
                        </Anchor>
                    </ListItem>
                    <ListItem>
                        <Anchor
                            to="/about/"
                            activeStyle={activeListItemStyle}
                            onClick={closeMenu}
                        >
                            אודות
                        </Anchor>
                    </ListItem>
                    <ListItem isOpen={openMenuIds.gallery}>
                        <Anchor to="#" onClick={menuToggleCreator('gallery')}>
                            גלריה
                            <AnchorIcon src={ArrowIcon}></AnchorIcon>
                        </Anchor>
                        <List>
                            {gallery.map((page) => (
                                <ListItem key={page.route}>
                                    <Anchor
                                        to={page.route}
                                        activeStyle={activeListItemStyle}
                                        onClick={closeMenu}
                                    >
                                        {page.name}
                                    </Anchor>
                                </ListItem>
                            ))}
                        </List>
                    </ListItem>
                    <ListItem isOpen={openMenuIds.packages}>
                        <Anchor to="#" onClick={menuToggleCreator('packages')}>
                            חבילות
                            <AnchorIcon src={ArrowIcon} />
                        </Anchor>
                        <List>
                            {packages.map((page) => (
                                <ListItem key={page.route}>
                                    <Anchor
                                        to={page.route}
                                        activeStyle={activeListItemStyle}
                                        onClick={closeMenu}
                                    >
                                        {page.name}
                                    </Anchor>
                                </ListItem>
                            ))}
                        </List>
                    </ListItem>
                    <ListItem>
                        <Anchor
                            to="/contact/"
                            activeStyle={activeListItemStyle}
                            onClick={closeMenu}
                        >
                            יצירת קשר
                        </Anchor>
                    </ListItem>
                </List>
            </Nav>
        </Root>
    );
}

export { MobileNavbar };
