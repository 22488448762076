import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Link } from 'gatsby';
import { ImageWithSize, NavbarMenuItem } from '../../types';

const Nav = styled.nav`
    font-size: 1.125rem;
`;

const Anchor = styled(Link)`
    display: block;
    padding: 10px 8px;
    margin: 0 2px;
    text-decoration: none;
    transition: all 0.25s ease;
    background-color: #ffffff;
    border-radius: 4px;
    transition: background-color 200ms linear;
    &:hover {
        background-color: #f4f2ec;
    }
`;

const List: any = styled.ul(
    () => `
    display: flex;
    background-color: #ffffff;

    & ${List} {
        width: 230px;
        opacity: 0;
        position: absolute;
        bottom:0;
        transform: translateY(100%);
        visibility: hidden;
        z-index: 300;
        display: block;
    }
`,
);

const ListItem = styled.li`
    position: relative;
    &:hover {
        & ${List} {
            opacity: 1;
            visibility: visible;
        }
    }
`;

const activeListItemStyle = {
    backgroundColor: '#cccccc',
};

interface DesktopNavbarProps {
    gallery: NavbarMenuItem[];
    packages: NavbarMenuItem[];
    className?: string;
}

function DesktopNavbar(props: DesktopNavbarProps) {
    const { gallery, packages, className } = props;
    return (
        <Nav className={className}>
            <List>
                <ListItem>
                    <Anchor to="/" activeStyle={activeListItemStyle}>
                        בית
                    </Anchor>
                </ListItem>
                <ListItem>
                    <Anchor to="/about/" activeStyle={activeListItemStyle}>
                        אודות
                    </Anchor>
                </ListItem>
                <ListItem>
                    <Anchor
                        to={'/gallery'}
                        partiallyActive
                        activeStyle={activeListItemStyle}
                    >
                        גלריה
                    </Anchor>
                    <List>
                        {gallery.map((page) => (
                            <ListItem key={page.route}>
                                <Anchor
                                    to={page.route}
                                    activeStyle={activeListItemStyle}
                                >
                                    {page.name}
                                </Anchor>
                            </ListItem>
                        ))}
                    </List>
                </ListItem>
                <ListItem>
                    <Anchor
                        to={'/packages'}
                        partiallyActive
                        activeStyle={activeListItemStyle}
                    >
                        חבילות
                    </Anchor>
                    <List>
                        {packages.map((page) => (
                            <ListItem key={page.route}>
                                <Anchor
                                    to={page.route}
                                    activeStyle={activeListItemStyle}
                                >
                                    {page.name}
                                </Anchor>
                            </ListItem>
                        ))}
                    </List>
                </ListItem>
                <ListItem>
                    <Anchor to="/contact/" activeStyle={activeListItemStyle}>
                        יצירת קשר
                    </Anchor>
                </ListItem>
            </List>
        </Nav>
    );
}

export { DesktopNavbar };
