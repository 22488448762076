import React, { ReactNode, useEffect, useMemo, useRef } from 'react';
import styled from '@emotion/styled';
import { Global, css } from '@emotion/react';
import emotionReset from 'emotion-reset';
import { Header } from './Header';
import { graphql, StaticQuery } from 'gatsby';
import { globalHistory } from '@reach/router';
import { Footer } from './Footer';
import { ImageWithSize, NavbarMenuItem, Settings } from '../../types';
import Metatags from './Metatags';
import { Helmet } from 'react-helmet';

const globalCss = css`
    ${emotionReset}

    * {
        box-sizing: border-box;
    }

    html {
        font-size: 16px;
    }

    body {
        font-family: 'Alef', sans-serif;
        color: #3e3e3e;
        overflow: hidden;
        min-height: 100vh;
    }

    a {
        color: inherit;
        text-decoration: none;
    }
`;

const Container = styled.div`
    position: relative;
    max-height: 100vh;
    overflow-y: auto;
    background-color: #ffffff;
`;

interface LayoutProps {
    children: ReactNode;
}

function Layout(props: LayoutProps) {
    const { children } = props;
    return (
        <StaticQuery
            query={graphql`
                query LayoutQuery {
                    site {
                        siteMetadata {
                            galleryNavigationItems {
                                name
                                route
                            }
                            packagesNavigationItems {
                                name
                                route
                            }
                            settings {
                                copyrightText
                                personalInfo {
                                    facebookUrl
                                    instagramUrl
                                    name
                                    phoneNumber
                                    whatsappUrl
                                }
                                openGraph {
                                    title
                                    url
                                    description
                                    image {
                                        original {
                                            url
                                            width
                                            height
                                        }
                                        medium {
                                            url
                                            width
                                            height
                                        }
                                        large {
                                            height
                                            url
                                            width
                                        }
                                    }
                                }
                                analytics {
                                    facebookDomainVerification
                                }
                            }
                        }
                    }
                }
            `}
            render={(data) => {
                const {
                    galleryNavigationItems,
                    packagesNavigationItems,
                    settings,
                } = data.site.siteMetadata as {
                    galleryNavigationItems: NavbarMenuItem[];
                    packagesNavigationItems: NavbarMenuItem[];
                    settings: Settings<ImageWithSize>;
                };

                const {
                    copyrightText,
                    personalInfo,
                    openGraph,
                    analytics: { facebookDomainVerification },
                } = settings;

                const scrollableRef = useRef<HTMLDivElement>(null);

                useEffect(() => {
                    return globalHistory.listen(({ action }) => {
                        if (action === 'PUSH' && scrollableRef.current) {
                            scrollableRef.current.scrollTo({
                                top: 0,
                                behavior: 'smooth',
                            });
                        }
                    });
                }, []);

                return (
                    <Container dir="rtl" ref={scrollableRef}>
                        {facebookDomainVerification && (
                            <Helmet>
                                <meta
                                    name="facebook-domain-verification"
                                    content={facebookDomainVerification}
                                />
                            </Helmet>
                        )}
                        <Global styles={globalCss} />
                        <Metatags
                            url={openGraph.url}
                            title={openGraph.title}
                            description={openGraph.description}
                            image={openGraph.image}
                        />
                        <Header
                            gallery={galleryNavigationItems}
                            packages={packagesNavigationItems}
                        />
                        {children}
                        <Footer
                            personalInfo={personalInfo}
                            copyrightText={copyrightText}
                        />
                    </Container>
                );
            }}
        />
    );
}

export default Layout;
